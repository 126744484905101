<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title> 固定消息 </template>
      <template #input>
        <a-input v-model="name" placeholder="请输入关键字" />
        <a-button type="primary" class="btn" :loading="loading" @click="search()">搜索</a-button>
        <!-- <a-button class="all_boder_btn" @click="toDetail(1)">新建</a-button> -->
      </template>
    </HeaderBox>

    <a-table
      class="table-template"
      :rowKey="item=>item.defaultMsgId"
      :columns="columns"
      :data-source="tableData"
      @change="onPage"
      :loading="loading"
      :scroll="{ x: 1500 }"
      :pagination="{
        total: total,
        showTotal: (res) => `共${total}条`,
        showQuickJumper: true,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '50', '100'],
      }"
    >
      <template slot="index" slot-scope="item, row, index">
        {{ (pageNumber - 1) * pageSize + index + 1 }}
      </template>
      <template slot="msgType" slot-scope="item">
        {{ item == 1 ? "系统消息" : "学习消息" }}
      </template>
      <template slot="operation" slot-scope="item, row">
        <span class="blueText">
          <span @click="editFilling(row)">编辑</span>
        </span>
      </template>
    </a-table>

    <a-modal
      v-model="edit"
      width="530px"
      title="文案"
      :centered="true"
      :closable="false"
    >
      <div class="all_content_box">
        <div class="L_R_inner">
          <span class="all_left_name">标题</span>
          <div class="right_Div">
            <a-input
              v-model="tidings.title"
              class="all_input"
              placeholder="提醒！您的账号不安全"
            />
          </div>
        </div>
        <div class="L_R_inner">
          <span class="all_left_name">请输入文案</span>
          <div class="right_Div">
            <a-textarea
              v-model="tidings.content"
              placeholder="请输入50字以内"
              :rows="4"
              style="resize: none"
            />
          </div>
        </div>
        <span>key需要{{ tidings.keyNum }}个占位符</span>
      </div>
      <template slot="footer">
        <a-button type="primary" @click="reviseitem()">确认</a-button>
        <a-button @click="editCancel">取消</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import HeaderBox from "@/components/HeaderBox.vue";
const columns = [
  {
    title: "序号",
    align: "center",
    dataIndex: "index",
    scopedSlots: {
      customRender: "index",
    },
  },
  {
    title: "消息分类",
    align: "center",
    dataIndex: "msgType",
    scopedSlots: {
      customRender: "msgType",
    },
  },
  {
    title: "推送场景",
    align: "center",
    dataIndex: "scene",
  },
  {
    title: "推送对象及触发条件",
    align: "center",
    dataIndex: "rule",
  },
  {
    title: "跳转页面",
    align: "center",
    dataIndex: "view",
  },
  {
    title: "操作",
    align: "center",
    dataIndex: "operation",
    scopedSlots: {
      customRender: "operation",
    },
  },
];
export default {
  // 可用组件的哈希表
  components: {
    HeaderBox,
  }, // 接收传值
  props: {}, // 数据对象
  data() {
    return {
      name: "",
      loading:false,
      columns,
      tableData: [],
      total: 0,
      pageNumber: 1, //页码
      pageSize: 10, // 页数
      edit: false,
      // 默认消息
      tidings: {
        defaultMsgId: "",
        content: "",
        title: "",
        keyNum: "",
      },
    };
  }, // 事件处理器
  methods: {
    editCancel(e) {
      this.edit = false;
    },
    editFilling(e) {
      // console.log(e);
      this.tidings.defaultMsgId = e.defaultMsgId;

      this.getReviseitem(e.defaultMsgId);
      this.edit = true;
    },
    toDetail(operation, id) {
      this.$router.push(
        "/admin/MessageManage/newSMS?" +
          "userType=1" +
          "&operation=" +
          operation +
          "&id=" +
          id
      );
    },
    // 分页
    onPage(e) {
      // console.log(e);
      this.pageNumber = e.current;
      this.pageSize = e.pageSize;
      this.getdataList();
    },
    // 搜索
    search() {
      this.pageNumber = 1;
      // this.pageSize = 10;
      this.getdataList();
    },
    // 获取数据
    getdataList() {
      this.loading = true
      this.$ajax({
        url: "/hxclass-management/default/msg/manage",
        method: "get",
        params: {
          current: this.pageNumber,
          size: this.pageSize,
          keyWord: this.name,
        },
      }).then((res) => {
        this.loading = false
        this.tableData = res.data.records;
        this.total = res.data.total;
      });
    },

    // 获取修改对象
    getReviseitem(e) {
      this.$ajax({
        url: "/hxclass-management/default/msg/manage/details/" + e,
        method: "get",
      }).then((res) => {
        // console.log(res);
        if (res.code == 200 && res.success) {
          this.tidings.title = res.data.title;
          this.tidings.content = res.data.content;
          this.tidings.keyNum = res.data.keyNum;
        }
      });
    },

    // 修改对象
    reviseitem(e) {
      this.$ajax({
        url: "/hxclass-management/default/msg/manage",
        method: "PUT",
        params: this.tidings,
      }).then((res) => {
        // console.log(res);
        if (res.code == 200 && res.success) {
          this.$message.success("操作成功");
          this.getdataList();
          this.edit = false;
        } else {
          this.$message.error(res.message);
        }
      });
    },
  }, // 生命周期-实例创建完成后调用
  created() {}, // 生命周期-实例挂载后调用
  mounted() {
    this.getdataList();
  }, // 生命周期-实例销毁离开后调用
  destroyed() {}, // 计算属性监听
  computed: {}, // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less"  scoped>
.all_main_wrap {
  min-height: 100%;
  .btn {
    margin: 0 24px 14px 0;
  }
}

.all_content_box {
  textarea.ant-input,
  .all_input {
    width: 340px;
  }
  textarea.ant-input {
    height: 120px;
  }
}
.all_left_name{
  width: 70px;
  text-align: right;
}
/deep/.ant-modal-header {
  border-bottom: none;
}
/deep/.ant-modal-title {
  text-align: center;
  font-size: 18px;
  color: #333;
}
/deep/.ant-modal-footer {
  text-align: center;
  border-top: none;
}
/deep/ .ant-modal-body {
  padding: 14px 24px 14px;
}
</style>
